import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { device } from "../utils/device"
import { Container, Layout, SEO, Grid, TextRow } from "../components"

const CardTitle = styled.h4`
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
`

const Card = styled.div`
  color: var(--color-text-primary);
  margin: auto;
  margin-bottom: 40px;

  &:hover {
    ${CardTitle} {
      text-decoration: underline;
    }
  }

  @media ${device.laptop} {
    width: auto;
    margin-left: 20px;
  }
`

const CardDescription = styled.span`
  font-size: 18px;
  color: var(--color-text-secondary);
  display: block;
`

const CardCompany = styled.span`
  color: var(--color-text-secondary);
  font-size: 16px;

  .company {
    font-weight: bold;
  }

  .divider {
    display: inline-flex;
    margin: 0 7px;
  }

  .period {
    font-size: 14px;
  }
`

const CustomTextRow = styled(TextRow)`
  @media ${device.laptop} {
    grid-column: 3 / span 8;
  }
`

const Item = ({ slug, title, subtitle, createdAt, readingTime, tags }) => (
  <a
    href={`https://medium.com/jonasantonelli/${slug}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Card>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{subtitle}</CardDescription>
      <CardCompany>
        <span className="company">{Math.ceil(readingTime)}min</span>
        <span className="divider">•</span>
        <span className="period">{createdAt}</span>
      </CardCompany>
    </Card>
  </a>
)

const Articles = ({ data }) => {
  const articles = data.allMediumPost.edges
  return (
    <Layout hideProfile>
      <SEO title="Articles" />
      <Container>
        <h1>Articles</h1>
        <Grid>
          <CustomTextRow>
            {articles.map(({ node }) => (
              <Item
                key={node.id}
                slug={node.uniqueSlug}
                title={node.title}
                subtitle={node.virtuals.subtitle}
                createdAt={node.createdAt}
                readingTime={node.virtuals.readingTime}
                tags={node.virtuals.tags}
              />
            ))}
          </CustomTextRow>
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allMediumPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          uniqueSlug
          createdAt(formatString: "LL")
          virtuals {
            subtitle
            readingTime
            tags {
              name
            }
          }
        }
      }
    }
  }
`

export default Articles
